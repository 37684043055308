var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CCard',{staticClass:"affiliate-table"},[_c('CDataTable',{attrs:{"items":_vm.items,"fields":_vm.fields,"fixed":"","hover":"","striped":"","bordered":"","itemsPerPage":_vm.limit},scopedSlots:_vm._u([{key:"#",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('td',[_vm._v(_vm._s(index + 1 + (_vm.activePage-1)*_vm.limit))])]}},{key:"type",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"affi-type"},[_c('span',[_vm._v(_vm._s(_vm.$t(("POLICY_TYPE_" + (item.type)).toUpperCase())))])])]}},{key:"proportion_share",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('span',[_vm._v(_vm._s(item.proportion_share)+"%")])])]}},{key:"currency_symbol",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('span',[_vm._v(_vm._s(item.currency_symbol || ''))])])]}},{key:"max_levels",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(_vm._s(item.max_levels ? item.max_levels : ''))])]}},{key:"action",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"btn-edit-affiliate"},[_c('CButton',{attrs:{"disabled":!_vm.isAllowedUpdate},on:{"click":function($event){return _vm.editAffiliate(item.id)}}},[_c('img',{staticClass:"hand edit-icon",attrs:{"src":require("@/assets/images/ico-edit-user.svg"),"alt":""}})])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }