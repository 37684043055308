<template>
  <CCard class="affiliate-table">
    <CDataTable :items="items" :fields="fields" fixed hover striped bordered :itemsPerPage="limit">
      <template ##="{item, index}">
        <td>{{index + 1 + (activePage-1)*limit}}</td>
      </template>

      <template #type="{item}">
        <td class="affi-type">
          <span>{{ $t(`POLICY_TYPE_${item.type}`.toUpperCase()) }}</span>
        </td>
      </template>

      <template #proportion_share="{item}">
        <td>
          <span>{{item.proportion_share}}%</span>
        </td>
      </template>

      <template #currency_symbol="{item}">
        <td>
          <span>{{item.currency_symbol || ''}}</span>
        </td>
      </template>

      <template #max_levels="{item}">
        <td>{{item.max_levels ? item.max_levels : ''}}</td>
      </template>

      <template #action="{item}">
        <td class="btn-edit-affiliate">
          <CButton :disabled="!isAllowedUpdate" @click="editAffiliate(item.id)">
            <img src="@/assets/images/ico-edit-user.svg" alt class="hand edit-icon" />
          </CButton>
        </td>
      </template>
    </CDataTable>
  </CCard>
</template>

<script>
export default {
  name: 'AffiliatePoliciesComponent',
  data() {
    return {
      fields: [
        {
          key: '#',
          label: this.$t('AFFILIATE_POLICIES_COMPONENT_LABEL_NUMBER'),
        },
        {
          label: this.$t('AFFILIATE_POLICIES_COMPONENT_TABLE_COL_NAME'),
          key: 'name',
        },
        {
          label: this.$t('AFFILIATE_POLICIES_COMPONENT_LABEL_TYPE'),
          key: 'type',
        },
        {
          label: this.$t('AFFILIATE_POLICIES_COMPONENT_TABLE_COL_PROPORTION_SHARE'),
          key: 'proportion_share',
        },
        {
          label: this.$t('AFFILIATE_POLICIES_COMPONENT_TABLE_COL_CURRENCY'),
          key: 'currency_symbol',
        },
        {
          label: this.$t('AFFILIATE_POLICIES_COMPONENT_TABLE_COL_MAX_LEVELS'),
          key: 'max_levels',
        },
        {
          label: '',
          key: 'action',
          _style: 'text-align: center;',
        },
      ],
    };
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    activePage: {
      type: Number,
      default: 1,
    },
    limit: {
      type: Number,
      default: 999,
    },
    editAffiliate: {
      type: Function,
      default: null,
    },
    isAllowedUpdate: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss">
.affiliate-table {
  .btn-edit-affiliate {
    text-align: center;
    button {
      padding: 0;
      font-size: 14px;
      font-weight: 400;
      color: #1414ce;
      outline: none;
      box-shadow: none;
    }
  }
}
</style>
