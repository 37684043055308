<template>
  <CRow class="affiliate-polices-container">
    <CCol col="12">
      <CCard>
        <CCardBody>
          <div class="label">
            <label>{{$t('AFFILIATE_POLICIES_LABEL_TITLE')}}</label>
            <CButton
              :disabled="!isAllowedCreate"
              @click="createAffiliate"
              class="btn btn-success btn-create-affiliate"
            >+ {{$t('AFFILIATE_POLICIES_BUTTON_ADD_POLICY')}}</CButton>
          </div>
          <AffiliatePoliciesComponent
            :items="items"
            :limit="limit"
            :activePage="activePage"
            :editAffiliate="editAffiliate"
            :isAllowedUpdate="isAllowedUpdate"
          />

          <StakingPagination
            :limit="limit"
            :total="total"
            :activePage="activePage"
            @changePage="onChangePage"
            @changePageSize="onChangePageSize"
          />
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import AffiliatePoliciesComponent from '@/components/AffiliatePolicies/AffiliatePoliciesComponent';
import StakingPagination from '@/components/StakingPagination';
import { endpoints, env } from '@/constants';
import { mapState, mapActions, mapGetters } from 'vuex';
import responseHelper from '@/utils/response-helper';

export default {
  name: 'AffiliatePolices',
  components: {
    AffiliatePoliciesComponent,
    StakingPagination,
  },
  data() {
    return {
      items: [],
      total: 0,
      limit: env.app.pageSize,
      activePage: 1,
      pageInput: 1,
      isAllowedCreate: false,
      isAllowedUpdate: false,
    };
  },
  computed: {
    ...mapGetters(['userPermissions']),
    offset() {
      return (this.activePage - 1) * this.limit;
    },
    pages() {
      return Math.ceil(this.total / this.limit);
    },
  },
  async mounted() {
    this.checkPermission();
    await this.fetchAffiliatePolices();
  },
  methods: {
    checkPermission() {
      if (this.userPermissions.length > 0) {
        this.userPermissions.forEach(el => {
          if (el.name == 'MEMBERSHIP_CREATE_AFFILIATE_POLICY') {
            this.isAllowedCreate = true;
          }
          if (el.name == 'MEMBERSHIP_UPDATE_AFFILIATE_POLICY') {
            this.isAllowedUpdate = true;
          }
        });
      }
    },
    async fetchAffiliatePolices() {
      const params = {
        limit: this.limit,
        offset: this.offset,
      };
      try {
        const res = await this.$http.get(endpoints.getAffiliatePolicies, { params });
        this.total = res.data.total;
        this.items = res.data.items;
      } catch (err) {
        this.$notify({
          group: 'notifications',
          type: 'error',
          title: this.$t('AFFILIATE_POLICIES_NOTIFY_ERROR_TITLE'),
          text: responseHelper.getErrorMessage(err),
        });
      }
    },
    async onChangePage(page) {
      this.activePage = page;

      this.fetchAffiliatePolices();
    },
    async onChangePageSize(value) {
      this.limit = value;
      this.activePage = 1;

      this.fetchAffiliatePolices();
    },
    createAffiliate() {
      this.$router.push('affiliate-policies/create');
    },
    editAffiliate(id) {
      this.$router.push(`affiliate-policies/${id}/edit`);
    },
  },
};
</script>

<style lang="scss">
.affiliate-polices-container {
  .label {
    display: flex;
    flex: 0 0 100%;
    width: 100%;
    justify-content: space-between;
    @media screen and (max-width: 580px) {
      flex-wrap: wrap;
      justify-content: center;
    }
    label {
      color: #657187;
      font-size: 18px;
      font-weight: 600;
      line-height: 34px;
      margin-bottom: 8px;
    }
    .btn-create-affiliate {
      background: #3b4b7e;
      border: none;
      border-radius: 32px;
      width: 175px;
      height: 32px;
      padding: 0 30px;
      color: #fff;
      // margin: 0 0 15px 15px;
    }
  }
}
</style>
